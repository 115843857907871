import React from 'react';
import { string } from 'prop-types';
import classnames from 'classnames';

const ICON_ID = 'card';
const namespace = 'ui-pdp-icon ui-pdp-icon--installments';

const IconInstallments = ({ className }) => (
  <svg
    className={classnames(namespace, className)}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconInstallments.propTypes = {
  className: string,
};

IconInstallments.defaultProps = {
  className: null,
};

IconInstallments.ICON_ID = ICON_ID;

export default React.memo(IconInstallments);
export { IconInstallments };
